import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { VueShowdownPlugin } from 'vue-showdown';

createApp(App)
    .use(store)
    .use(router)      
    .use(VueShowdownPlugin, {
      options: {
        emoji: true,
        simpleLineBreaks: true
      },
    })
    .mount('#app')
