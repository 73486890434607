import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/docs/privacy',
    name: 'privacy',
    component: function () {
      return import('../views/PrivacyView.vue')
    }
  },
  {
    path: '/promo/drozda',
    name: 'Promo',
    component: function () {
      return import('../views/Promo.vue')
    }
  },
  {
    path: '/docs/fastapi',
    name: 'fastapi',
    component: function () {
      return import('../views/FastAPIView.vue')
    }
  },
  {
    path: '/docs/terms',
    name: 'terms',
    component: function () {
      return import('../views/TermsView.vue')
    }    
  },
  {
    path: '/docs/eula',
    name: 'eula',
    component: function () {
      return import('../views/UELAView.vue')
    }
  },
  {
    path: '/support',
    name: 'support',
    component: function () {
      return import('../views/SupportView.vue')
    }    
  },
  {
    path: '/send_message',
    name: 'send_message',
    component: function () {
      return import('../views/SendMessageView.vue')
    }    
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
