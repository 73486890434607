<template>
  <div class="volt-container main-inner-container">
    <div class="main_container_title" style="margin-top: 80px;">
      <span>What do we offer?</span>
    </div>

    <div style="float: left; width: 100%; margin-top: 20px;background: #EDEFFA; padding-bottom: 40px; background-image: url(/img/servers.png); background-repeat: no-repeat; background-position: right 40px bottom 50px; background-size: auto 40%" class="features-mobile">
      <div style="margin-top: 30px; margin-left: 20px;">
        <span style="font-size: 28px; font-style: normal; font-weight: 700; line-height: normal;">Server selection</span>
      </div>
      <div style="margin-left: 20px; margin-top: 20px;">
        <span style="font-size: 18px; font-style: normal; font-weight: 400; line-height: normal;">Choose from variety of<br/>locations around the world</span>
      </div>
    </div>

    <div style="float: left; width: 100%; margin-top: 20px;background: #5D23BB; padding-bottom: 40px; background-image: url(/img/fast.png); background-repeat: no-repeat; background-position: right 40px bottom 50px; background-size: auto 40%" class="features-mobile">
      <div style="margin-top: 30px; margin-left: 20px; color: white">
            <span style="font-size: 28px; font-style: normal; font-weight: 700; line-height: normal;">Fast connection</span>
        </div>
        <div style="margin-left: 20px; margin-top: 20px; color: white">
            <span style="font-size: 18px; font-style: normal; font-weight: 400; line-height: normal;">Blazing fast connection<br/>
              for smooth browsing
            </span>
        </div>
    </div>

    <div style="float: left; width: 100%; margin-top: 20px;background: #EDEFFA; padding-bottom: 40px; background-image: url(/img/unblockable.png); background-repeat: no-repeat; background-position: right 40px bottom 50px; background-size: auto 40%" class="features-mobile">
      <div style="margin-top: 30px; margin-left: 20px; ">
            <span style="font-size: 28px; font-style: normal; font-weight: 700; line-height: normal;">Invulnerable<br/>to block</span>
        </div>
        <div style="margin-left: 20px; margin-top: 20px; ">
            <span style="font-size: 18px; font-style: normal; font-weight: 400; line-height: normal;">We use technology which<br/>
                  can’t be blocked
            </span>
        </div>
    </div>

    <div style="float: left; width: 100%; margin-top: 20px;background: #5D23BB; padding-bottom: 40px; background-image: url(/img/devices.png); background-repeat: no-repeat; background-position: right 40px bottom 50px; background-size: auto 40%" class="features-mobile">
      <div style="margin-top: 30px; margin-left: 20px;color: white">
        <span style="font-size: 28px; font-style: normal; font-weight: 700; line-height: normal;">Multiple devices</span>
      </div>
      <div style="margin-left: 20px; margin-top: 20px;color: white">
        <span style="font-size: 18px; font-style: normal; font-weight: 400; line-height: normal;">Browse securely on<br/>
                  up to 5 devices</span>
      </div>
    </div>

    


    <div style="float: left; width: 100%; margin-top: 100px;" class="features-desctop">

      <div style="" class="features_1">

        <div style="float: left; width: calc(100% - 10px); height: 400px; border-radius: 25px; background: #EDEFFA;  background-image: url(/img/servers.png); background-repeat: no-repeat; background-position: right 40px bottom 40px; background-size: auto 40%">
          <div style="margin-top: 50px; margin-left: 50px;">
            <span style="font-size: 45px; font-style: normal; font-weight: 700; line-height: normal;">Server selection</span>
          </div>
          <div style="margin-left: 50px; margin-top: 40px;">
            <span style="font-size: 18px; font-style: normal; font-weight: 400; line-height: normal;">Choose from variety of locations<br/>around the world</span>
          </div>
        </div>

        <div style="height: 265px; margin-top: 20px; float: left; width: calc(100% - 10px);  border-radius: 25px; background: #5D23BB;  background-image: url(/img/fast.png); background-repeat: no-repeat; background-position: right 40px bottom 40px; background-size: auto 40%">
          <div style="margin-top: 50px; margin-left: 50px; color: white">
            <span style="font-size: 45px; font-style: normal; font-weight: 700; line-height: normal;">Fast connection</span>
          </div>
          <div style="margin-left: 50px; margin-top: 40px; color: white">
                <span style="font-size: 18px; font-style: normal; font-weight: 400; line-height: normal;">Blazing fast connection<br/>
                  for smooth browsing</span>
          </div>
        </div>
      </div>

      <div class="features_1">
        <div style="height: 265px; margin-left: 20px; float: left; width: calc(100% - 10px);  border-radius: 25px; background: #5D23BB;  background-image: url(/img/devices.png); background-repeat: no-repeat; background-position: right 40px bottom 40px; background-size: auto 40%">
          <div style="margin-top: 50px; margin-left: 50px; color: white">
            <span style="font-size: 45px; font-style: normal; font-weight: 700; line-height: normal;">Multiple devices</span>
          </div>
          <div style="margin-left: 50px; margin-top: 40px; color: white">
                <span style="font-size: 18px; font-style: normal; font-weight: 400; line-height: normal;">Browse securely on<br/>
                  up to 5 devices</span>
          </div>
        </div>

        <div style="margin-left: 20px; margin-top: 20px; float: left; width: calc(100% - 10px); height: 400px; border-radius: 25px; background: #EDEFFA;  background-image: url(/img/unblockable.png); background-repeat: no-repeat; background-position: right 40px bottom 40px; background-size: auto 40%">
          <div style="margin-top: 50px; margin-left: 50px;">
            <span style="font-size: 45px; font-style: normal; font-weight: 700; line-height: normal;">Invulnerable<br/>to block</span>
          </div>
          <div style="margin-left: 50px; margin-top: 40px;">
                <span style="font-size: 18px; font-style: normal; font-weight: 400; line-height: normal;">We use technology which<br/>
                  can’t be blocked</span>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>
<script setup>
</script>

<style>

.features_1 {
  width: 50%; float: left;
}

.features-mobile {
  display: none;
}

@media only screen and (max-width: 460px) {
  .features-desctop {
    display: none;
  }
  .features-mobile {
    display: block
  }
}
</style>