<template>
  <router-view/>
</template>

<style>
#app {
  font-family: Roboto, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  text-align: center;
}

html, body, #app, main {
  height: 100%;
  margin: 0px;
}

.volt-container {
  max-width: 1000px;
  width: 1000px;
  padding-left: calc(var(--bs-gutter-x) * .5);
  padding-right: calc(var(--bs-gutter-x) * .5);
  margin-left: auto;
  margin-right: auto;
}

.main-outer-container {
  text-align: left;  width: 100%;
}

.main_container_title span {
  font-size: 50px; font-style: normal; font-weight: 700; line-height: normal;
}
.main_container_title {
  width: 100%; float: left; text-align: center;
}


@media only screen and (max-width: 460px) {
  html, body, #app, main {
    width: 100%;
    overflow-x: hidden;
    margin: 0px;
  }
  .volt-container {
    max-width: 100%;
  }

  .main_container_title span {
    font-size: 20px; font-style: normal; font-weight: 700; line-height: normal;
  }
  .main_container_title {
    width: 100%; float: left; text-align: left; margin-left: 20px;
  }
  .main-outer-container {
    text-align: left;  width: 100%; height: 100%;
  }
}

.support-button-offset {
    margin-left: 25px;
}

.support-button-white {
    border: none;
    width: 218px; 
    height: 50px;
    border-radius: 40px; 
    border: #7C38EB 3px solid ;
    background: white;
    color: #7C38EB;
    text-align: center;
    font-family: Roboto;
    font-size: 19px;
    font-style: normal;
    font-weight: 500;
}
.support-button {
    cursor: pointer;
    border: none;
    width: 218px; 
    height: 50px;
    border-radius: 40px; 
    background: #7C38EB;
    color: #FFF;
    text-align: center;
    font-family: Roboto;
    font-size: 19px;
    font-style: normal;
    font-weight: 500;
}
.support-form-input {
    width: calc(100% - 40px); 
    height: 50px; 
    border-radius: 10px;
    background: rgba(19, 15, 26, 0.07); 
    border: none;
    color: #222040;
    font-family: Roboto;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    padding-left: 20px;
    padding-right: 20px;
}
.support-form-title-offset {
    margin-top: 15px
}
.support-form-title {
    width: 100%; 
    float: left; 
    text-align: left; 
    height: 30px;
    color: #222040;
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
}
.support-form {
    width: 100%; 
    float: left; 
    text-align: center;
}
.support-inner-form {
    width: 50%; 
    margin-left: 25%
}

@media only screen and (max-width: 460px) {
    .support-inner-form {
        width: 70%; 
        margin-left: 15%
    }
    .support-button-offset {
        margin-top: 15px;
        margin-left: 0px;
    }
}
</style>

