<template>
  <div class="volt-container main-inner-container main-plans-container">
    <div class="main_container_title" style="margin-top: 150px">
      <span>Choose your plan</span>
    </div>


    <div style="float: left; width: 100%; margin-top: 50px;">
      <div class="main-plan-offer-outer">
        <div class="main-plan-offer-inner">

          <div class="main-plan-offer-title">
            <span>Powered 1 Year</span>
          </div>
          <div class="main-plan-offer-subtitle">
            <span>subscription</span>
          </div>

          <div class="main-plan-offer-prices-inner">
            <div class="main-plan-offer-prices-title">
              <span>$3.8/mo</span>
            </div>
            <div class="main-plan-offer-prices-subtitle">
              <span>$45.99 annually</span>
            </div>
          </div>
        </div>
      </div>

      <div class="main-plan-offer-outer" style="margin-left: 20px">
        <div class="main-plan-offer-inner">

          <div class="main-plan-offer-title">
            <span>Powered 1 Month</span>
          </div>
          <div class="main-plan-offer-subtitle">
            <span>subscription</span>
          </div>

          <div class="main-plan-offer-prices-inner">
            <div class="main-plan-offer-prices-title">
              <span>$7.99/mo</span>
            </div>
            <div class="main-plan-offer-prices-subtitle">
              <span>Billed monthly</span>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="main-plans-features-outer">

      <div class="main-plans-feature-inner">
        <div class="main-plans-feature-icon">
          <img src="/img/offer_1.png">
        </div>
        <div class="main-plans-feature-content">
          <div class="main-plans-feature-title">
            <span>Browse with no limits</span>
          </div>
          <div class="main-plans-feature-subtitle">
            <span>Visit any web resources
            and pick the location needed</span>
          </div>
        </div>
      </div>

      <div class="main-plans-sep"></div>

      <div class="main-plans-feature-inner">
        <div class="main-plans-feature-icon">
          <img src="/img/offer_2.png">
        </div>
        <div class="main-plans-feature-content">
          <div class="main-plans-feature-title">
            <span>Fast connection</span>
          </div>
          <div class="main-plans-feature-subtitle">
            <span>Stream video and download large files with excellent speed</span>
          </div>
        </div>
      </div>

      <div class="main-plans-sep"></div>

      <div class="main-plans-feature-inner">
        <div class="main-plans-feature-icon">
          <img src="/img/offer_3.png">
        </div>
        <div class="main-plans-feature-content">
          <div class="main-plans-feature-title">
            <span>Your data is safe</span>
          </div>
          <div class="main-plans-feature-subtitle">
            <span>All your information is hidden from any ad trackers or tricky eyes </span>
          </div>
        </div>
      </div>

    </div>

    <div class="main-plans-desc-outer">
      <span style="font-size: 21px; font-style: normal; font-weight: 500; line-height: normal;">Of course you can also try our services for free with limits</span>
    </div>

    <div style="width: 100%; float:left;  height: 100px;">

    </div>

  </div>
</template>

<style>
.main-plans-desc-outer {
  float: left;width: 100%; background-color: #EDEFFA; height: 128px; border-radius: 25px; margin-top: 20px; display: flex; align-items: center;  justify-content: center;
}
.main-plans-sep {
  background: rgba(237, 239, 250, 0.50); width: 1px; float: left; margin-top: 20px; height: calc(100% - 40px)
}
.main-plans-feature-icon img {
  width: 48px
}
.main-plans-feature-subtitle span {
  font-size: 12px; font-style: normal; font-weight: 400; color: white
}
.main-plans-feature-subtitle {
  margin-top: 6px
}
.main-plans-feature-title span {
  font-size: 17px; font-style: normal; font-weight: 600; line-height: normal; color: white
}
.main-plans-feature-title {
  margin-top: 4px
}
.main-plans-feature-content {
  float: left; width: calc(100% - 60px - 48px - 20px); margin-left: 20px;
}
.main-plans-feature-icon {
  float: left; width: 48px; margin-top: 12px; margin-left: 12px
}
.main-plans-feature-inner {
  float: left; width: calc(33% - 1px); height: 100%;display: flex; align-items: center;  justify-content: center;
}
.main-plans-features-outer {
  float: left;width: 100%; background-color: #5D23BB; height: 128px; border-radius: 25px; margin-top: 20px
}

.main-plan-offer-prices-subtitle span {
  color: rgba(34, 32, 64, 0.50); font-size: 16px; font-style: normal; font-weight: 400; line-height: normal;
}
.main-plan-offer-prices-subtitle {
  float: left; width: 50%; text-align: center;line-height: 35px
}
.main-plan-offer-prices-title span {
  font-size: 30px; font-style: normal; font-weight: 500; line-height: normal;
}
.main-plan-offer-prices-title {
  float: left; width: 50%; text-align: center
}
.main-plan-offer-prices-inner {
  width: 100%; margin-top: 44px
}
.main-plan-offer-subtitle span {
  font-size: 16px; font-style: normal; font-weight: 500; line-height: normal;
}
.main-plan-offer-subtitle {
  margin-top: 15px; text-align: center
}
.main-plan-offer-title span {
  font-size: 45px; font-style: normal; font-weight: 500; line-height: normal;
}
.main-plan-offer-title {
  margin-top: 50px; text-align: center
}
.main-plan-offer-inner {
  float: left; width: calc(100%); height: 257px; border-radius: 25px; background: #EDEFFA;
}
.main-plan-offer-outer {
  width: calc(50% - 10px); float: left;
}

.main-plans-container {

}

@media only screen and (max-width: 460px) {
  .main-plans-container {
    display: none;
  }
}
</style>


<script setup>
</script>