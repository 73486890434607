<template>
    <div class="volt-container main-inner-container " style="margin-bottom: 80px;">
        <div class="main-servers-outer ">
            <div style="float: left; margin-left: 40px; width: calc(100% - 80px); margin-top: 10px;">
                <h1>
                    Buy VPN activation keys<br/>
                    in our telegram bot
                </h1>
            </div>
            <div style="float: left; margin-left: 40px; width: calc(100% - 80px); margin-top: 10px;">
                <h1>
                    Купить VPN ключи активации<br/>
                    в нашем Телеграм боте (подходит для просмотра youtube)
                </h1>
                <br/><br/>
                <h5>
                    🚀 Очень высокая скорость (100 - 400 Mb/s)<br/>
                    🏄‍ Доступ ко всем сайтам и сервисам<br/>
                    💳 Оплата РУ картами<br/>
                    🌏 Большой выбор локаций серверов<br/>
                    🔑 До 3 ключей в подписке (для всей семьи или разных устройств)<br/>
                    📉 Самая низкая цена на рынке - 169 руб / месяц<br/>
                </h5>                                    
            </div>
            <div style="float: left; margin-left: 40px; width: calc(100% - 80px); margin-top: 10px; margin-bottom: 40px;">
                <div style="width: 60px; float: left; height: 60px">
                    <img src="/img/tg.png" style="width: 60px"/>
                </div>
                <div style="float: left; height: 60px; line-height: 60px; margin-left: 20px; font-size: 30px;">
                    <a href="https://t.me/silver_vpn_bot">@silver_vpn_bot</a>
                </div>
            </div>
            
        </div>
    </div>
</template>

<style scoped>
.main-servers-outer {
  float: left;width: 100%; background-color: #FDD238; border-radius: 25px; margin-top: 20px;  align-items: center;  justify-content: center;
}

</style>