<template>
  <div style="width: 100%;background: #5D23BB;">
    <div class="volt-container main-volt-box">

      <div class="main_header_box">
        <div style="width: 100%">
          <div class="main_header_box_title">
            Reliable, fast<br/>
            and stable<br/>
            <span style="color: white">VPN service</span>
          </div>
          <div style="margin-top: 50px">
            <a href="https://apps.apple.com/us/app/volt-tunnel-vpn/id6475793608">
              <img src="/img/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg" height="51"/>
            </a>
            

            <!-- <img src="/img/google-play-badge.png" height="31" style="margin-left: 15px;"/> -->
          </div>
        </div>

      </div>

      <div class="main_header_banner" style="background-image: url(/img/home_banner.png);">

      </div>
    </div>
  </div>
</template>

<style scoped>
.main_header_box_title {

}
.main_header_box {
  width: 50%; height: 100%; float: left; margin-top: 70px;
}

.main_header_box_title {
  font-size: 60px; font-style: normal; font-weight: 700; color: #FDD238;
}

.main_header_banner {
  width: 50%; height: 100%; float: left;  background-repeat: no-repeat; background-position: center; background-size: auto 60%
}

.main-volt-box {
  width: 100%; ; height: 468px; text-align: left
}

@media only screen and (max-width: 460px) {
  .main_header_box {
    width: calc(100% - 20px); float: left; margin-top: 70px;
    margin-left: 20px;
  }

  .main_header_box_title {
    font-size: 28px; font-style: normal; font-weight: 700; color: #FDD238;
  }

  .main_header_banner {
    display: none;
  }

  .main-volt-box {
    width: 100%; ; height: 280px; text-align: left
  }
}
</style>