<template>
  <footer>
    <div class="footer-container">
      <div class="volt-container">
        <div class="footer-container-title">
          <span style="color: #B8BDD9;  font-style: normal; font-weight: 400; line-height: 62px;">
            © 2023-2024 CatAds OU. All rights reserved.
          </span>
        </div>

        <div class="footer-container-utility" style="">
          <div style="width: 100%; float: left">
            <div style="float: right;; margin-left: 30px">
              <a href="/docs/privacy" style="color: #B8BDD9">Privacy Policy</a>
            </div>
            <div style="float: right; margin-left: 30px;">
              <a href="/docs/terms" style="color: #B8BDD9">Terms and Conditions</a>
            </div>
          </div>

          <div style="width: 100%; float: left">
            <div style="float: right; margin-left: 30px">
              <a href="/docs/eula" style="color: #B8BDD9">EULA</a>
            </div>
            <div style="float: right; margin-left: 30px;">
              <a href="/support" style="color: #B8BDD9">Contact support</a>
            </div>
          </div>
          
        </div>
      </div>
    </div>

  </footer>
</template>

<style scoped>

.footer-container {
  width: 100%;  float: left; background: #222040; height: calc(62px * 2); text-align: left;
}
.footer-container-title {
  float: left; height: 62px;
}

.footer-container-utility {
  float: right; height: 62px;color: #B8BDD9; font-size: 12px; font-style: normal; font-weight: 400; line-height: 62px;
}

@media only screen and (max-width: 460px) {
  .footer-container {
    width: 100%;  float: left; background: #222040; height: 180px; text-align: left; 
  }

  .footer-container-title {
    height: 62px; margin-left: 20px; font-size: 10px;float: left
  }

  .footer-container-utility {
    height: 62px;color: #B8BDD9; font-size: 8px; font-style: normal; font-weight: 400; line-height: 42px; float: left; margin-left: 20px; 
  }
}
</style>