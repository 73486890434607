<template>
  <header>
    <div class="main-header">
      <div class="volt-container" style="text-align: left">
        <div class="main-header-logo">
          <span>VOLT VPN</span>
        </div>
        <!--div class="main-header-item">
          <span>
            <a href="">Features</a>
          </span>
        </div>
        <div class="main-header-item">
          <span>
            <a href="">Pricing</a>
          </span>
        </div>
        <div class="main-header-item">
          <span>
            <a href="">Servers</a>
          </span>
        </div-->
        <div class="main-header-item">
          <span>
            <a href="/support">Help & support</a>
          </span>
        </div>
      </div>
    </div>
  </header>
</template>

<style scoped>
.main-header-item a {
  text-decoration: none;
  color: white;
}
.main-header-item span {
  font-size: 16px; font-style: normal; font-weight: 400; line-height: normal; color: white
}
.main-header-item {
  float: right; padding-left: 15px; line-height: 48px; margin-left: 35px;
}
.main-header-logo span {
  font-size: 22px; font-style: normal; font-weight: 600; line-height: normal; color: white
}
.main-header-logo {
  float: left; line-height: 52px;
}
.main-header {
  width: 100%; float: left; background: #222040; height: 50px;
}

@media only screen and (max-width: 460px) {
  .main-header-logo span {
    font-size: 22px; font-style: normal; font-weight: 600; line-height: normal; color: white
  }
  .main-header-logo {
    float: left; line-height: 52px; margin-left: 20px;
  }
  .main-header-item {
    display: none; float: right;
  }
}
</style>